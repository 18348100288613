import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día -06",
  week: "Semana 0",
  day: "07",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-07",
  path: "/cronologia/semana-0#dia-07-mar",
};
const Day07M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Eslovaquia declara su primer caso confirmado.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Serbia se suma a la lista de países contagiados.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, hasta el momento se han registrado 430
          casos (65 más que el día anterior) y 8 fallecidos (1,9% de los casos
          positivos).
        </ModText>
        <ModText>
          El <strong>Ministerio de Sanidad</strong> mantiene el escenario de
          contención (nivel 1).
        </ModText>

        <ModImage
          src="/images/svg/07-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          Las comunidades autónomas con una mayor incidencia acumulada en los
          últimos días son Madrid (174 casos y 4 fallecidos), País Vasco (45
          casos y 1 fallecido), La Rioja (39 casos), Comunidad Valenciana (30
          casos y 1 fallecido) y Andalucía (27 casos).
        </ModText>
        <ModText>
          La <strong>Consejería de Sanidad de la Comunidad de Madrid</strong>{" "}
          (donde se registra un mayor número de casos), entre otras medidas, ha
          reforzado la plantilla de trabajadores de los diferentes centros y
          servicios sanitarios. Además, se ha decidido el cierre temporal de más
          de 200 centros de mayores.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day07M;
